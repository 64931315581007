import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
const PrivacyPolicy = ({ data, location }) => {
  return (
    <div>
      <Layout location={location} title='dxdailypost'>
	<SEO title="個人情報保護方針" description=""/> 
        <h2 style={{fontSize: 18 + 'px'}}>個人情報保護方針</h2>
        <p>当社は、お客様との信頼関係を損なう事のないように、お客様からお預かりする個人情報を適切に取扱うべく、次のような方針を定め、お客様に関する個人情報の保護に努めております。</p>
        <h2 className="aboutus_title">個人情報の取得</h2>
        <p>当社は、法令等を遵守した適法かつ公正な手段によって、お客様の個人情報を取得させていただきます。</p>
        <h2 className="aboutus_title">個人情報の取り扱い</h2>
        <p>当社では、個人情報を適正に取扱うため、個人情報に関する法令その他の規範を遵守致します。個人情報の取扱いに関する規程類を明確化し、従業者に周知徹底します。また、個人情報を取扱う委託先等に対しても、個人情報の適切な取扱いを要請します。</p>
        <h2 className="aboutus_title">個人情報の利用目的</h2>
        <p>当社では、お客様に、より質の高いサービスや出版物などを提供させていただくために、氏名、電子メールアドレス、職業、勤務先、役職、生年月日、性別、住所、電話番号等のお客様の個人情報を取得させていただきます。お客様からご提供いただきました個人情報は、以下の目的のためにのみ利用いたします。お客様からご提供いただいた個人情報を、以下の目的の範囲を超えて利用する必要が生じた場合には、その都度お客様にご通知の上、必要な手続きとらせていただきます。</p>
        <ol style={{marginLeft: 5 + '%'}}>
          <li>当社のサービスにつきご登録頂いたお客様に対し、当社の情報配信サービスを提供するため</li>
          <li>お客様からのお問い合わせ及びご依頼への対応のため</li>
          <li>ダイレクトマーケティング、テレマーケティング等の方法による当社の提供する商品、サービスに関する情報の提供および提案のため</li>
          <li>雑誌・書籍、セミナー、イベント等のご案内及びアンケート調査等のため</li>
          <li>当社媒体に掲載された広告等の広告主又は当社媒体において紹介された製品の販売元（以下「広告主等」といいます。）に対し、下記「個人情報の利用及び第三者への開示・提供」に従って提供するため</li>
          <li>お客様によるサービスの利用を分析し、新規サービスの開発や既存サービスの改善をするため</li>
          <li>お客様がご覧になるコンテンツや広告を、お客様の職業、勤務先業種、年齢、役職等の属性又は当社の運営するウェブサイトの閲覧履歴などによりパーソナライズするため</li>
          <li>当社媒体に掲載された情報に含まれるIT関連製品・サービスなどの提供者である企業および団体とお客様との間のコミュニケーションを円滑にするため</li>
        </ol>
        <h2 className="aboutus_title">個人情報の管理および保護</h2>
        <p>個人情報への不正アクセスや、個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく、事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。また、万一個人情報の漏えい等の事故が生じた際には速やかに是正措置を講じます。</p>
        <h2 className="aboutus_title">個人情報の利用および第三者への開示・提供</h2>
        <p>当社は、お客様の同意がない限り、お客様の個人情報を前述の利用目的以外に使用いたしません。また、以下の場合及び本プライバシーポリシーに明確に規定されている場合を除き、お客様の同意なく、お客様の個人情報を第三者に開示・提供することはありません。</p>
        <ul style={{marginLeft: 5 + '%'}}>
          <li>法令の規定に基づく場合</li>
          <li>お客様の資料請求・お問合せ等に基づく広告主等への提供の場合</li>
        </ul>
        <p>当社は、以下に該当する場合には、お客様が資料請求等をされた広告の広告主又はお客様が詳細情報の閲覧等を行った商品の販売元に対し、お客様の個人情報を提供します。このようにして提供された個人情報は、広告主等において管理されることになります。この場合の個人情報の取扱いの詳細については広告主等にお問い合わせください。</p>
        <ul style={{marginLeft: 5 + '%'}}>
          <li>お客様が、当社媒体に掲載された広告の資料請求又はお問合せをされた場合</li>
          <li>お客様が、当社の運営するウェブサイト上において、当社媒体に掲載された広告又は当該広告の広告主の詳細情報を閲覧された場合</li>
          <li>お客様が、当社の運営するウェブサイトに掲載された商品の詳細情報を閲覧された場合</li>
        </ul>
        <p>広告主に提供される個人情報の範囲は、上記「個人情報の利用目的」欄の記載の個人情報のうち、氏名、電子メールアドレス、職業、勤務先、役職、住所及び電話番号です。また、広告主への提供の手段及び方法は、当社の管理するシステム、CD-ROMなどの電子媒体、紙などのアナログ媒体を通じての交付となります。広告主への提供停止を希望される場合には、下記「情報の照会・訂正・削除および第三者提供の停止」欄記載のお手続をお取りください。</p>
        <h2 className="aboutus_title">個人情報の共同利用</h2>
        <p>当社は、当社と親子会社の関係にある企業や関連会社等のグループ会社との間で、利用目的の達成に必要な範囲内において、お客様の個人情報を共同利用することがあります。共同利用される個人情報の範囲及びその利用目的は、上記「個人情報の利用目的」欄の記載と同様です。また、共同利用における管理責任者は、「株式会社デジタルリサーチ」となります。</p>
        <h2 className="aboutus_title">情報の照会・訂正・削除および第三者提供の停止</h2>
        <p>お客様は、当社に対してご自身の個人情報の開示等（利用目的の通知・開示、個人情報の内容の訂正・追加・削除、個人情報の利用の停止または消去、広告主等の第三者への提供の停止）に関して、下記の当社問合せ窓口に申し出ることができます。その際、当社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。
また、当社の個人情報の取扱いに関する苦情及びご相談等につきましても、下記の当社お問合せ窓口までお申し出ください。なお、お客様が個人情報の利用停止もしくは消去、又は広告主等の第三者への提供停止の手続きをされたときは、当社の提供するサービス等の全部又は一部の利用ができなくなる場合があります。</p>
        <h2 className="aboutus_title">情報処理の外部委託</h2>
        <p>個人情報の取り扱いを委託する場合は、十分な個人情報の保護水準を満たしている委託先を選定し、安全管理が図られるよう、委託先に対する必要かつ適切な監督を行います。</p>
        <h2 className="aboutus_title">行動ターゲティング広告について</h2>
        <p>当社では、お客様の興味・関心に合わせて、より有益と思われる広告を配信する「行動ターゲティング広告」を提供しております。</p>
        <ul style={{marginLeft: 5 + '%'}}>
          <li>利用している情報</li>
        </ul>
        <p>当社では、行動ターゲティング広告の提供のため、お客様が閲覧したWebサイトの情報、閲覧した日時、表示または検索した製品やサービスなどに関する情報などを「クッキー（Cookie）」を用いて取得し利用するほか、お客様が会員登録時に入力された性別や年代の情報などを利用しますが、特定の個人を識別することができる情報は利用していません。
なお、行動ターゲティング広告の提供のために利用する情報の利用対象期間は最長で「１年間」です。</p>
        <ul style={{marginLeft: 5 + '%'}}>
          <li>行動ターゲティング広告の無効化</li>
        </ul>
        <p>行動ターゲティング広告の提供を希望されないお客様は、以下の方法により、表示を無効にする設定を行うことができます。なお、無効化後も、行動ターゲティングによらない通常の広告は表示されます。</p>
        <p>［無効化の方法］</p>
        <p>このような広告配信を停止のご希望の場合、下記オプトアウトページにて設定を変更することができます。</p>
        <p>&nbsp;&nbsp;<a href="https://policies.google.com/technologies/ads?gl=jp">Googleのオプトアウトページ</a></p>
        <p>&nbsp;&nbsp;<a href="https://btoptout.yahoo.co.jp/optout/index.html">Yahoo!のオプトアウトページ</a></p>
        <p>&nbsp;&nbsp;<a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">Facebookのオプトアウトページ</a></p>
      </Layout>
    </div>
  )
}
export default PrivacyPolicy
